
import cmsBlock from '~/mixins/cmsBlock'
import lazyBgImage from '~/mixins/lazyBgImage'
import mentalhealthIco from '~/assets/sprite/mediclub/v3/survey/ico/mentalhealth.svg?raw'
import generalhealthIco from '~/assets/sprite/mediclub/v3/survey/ico/generalhealth.svg?raw'
import preferencesIco from '~/assets/sprite/mediclub/v3/survey/ico/preferences.svg?raw'

export default {
  name: 'CmsMediclub3BlockSurvey',
  mixins: [cmsBlock, lazyBgImage],

  data () {
    return {
      mentalhealthIco,
      generalhealthIco,
      preferencesIco,
      fallbackContent: {
        header: 'Get your',
        subheader: 'personalised results',
        text1: 'First step to improve your daily lifestyle and see how you compare to others',
        text2: 'Take advantage of the free Self Care Program and find out what you can do to be well, feel good and enjoy life.',
        bgImg: '/mc/v3/block6/ppl.png',
        cards: [{
          disabled: false,
          ico: 'mentalhealth',
          img: '/mc/v3/survey/1.png',
          info: 'Check your mood and how you rate your wellbeing. We will tell you what to do to feel good and enjoy life.',
          to: '/customer/questionnaire/mental-health',
          magentoId: 'mental_health_questionnaire',

          mobile: {
            title: 'Your <b>Mental Health</b>',
            btn: 'Check now',
          },

          desktop: {
            title: '<b>Receive a gift</b>',
            text: 'Just share information about <b>your Mental Health</b>',
            btn: 'Get suprise',
          },

          done: {
            title: 'All done :)',
            text: 'Thank You. You shared information about <b>your Mental Health</b>',
            btn: 'Update',
          },
        },

        {
          disabled: false,
          ico: 'preferences',
          img: '/mc/v3/survey/2.png',
          info: 'Let\'s get to know each other better and tell us what you like and what interests you, so that we can provide you with exactly what you need',
          to: '/customer/questionnaire/participants',
          magentoId: 'main_questionnaire',

          mobile: {
            title: 'Your <b>Preferences</b>',
            btn: 'Check now',
          },

          desktop: {
            title: '<b>Receive a gift</b>',
            text: 'Just share information about <b>your Preferences</b>',
            btn: 'Get suprise',
          },

          done: {
            title: 'All done :)',
            text: 'Thank You. You shared information about <b>your Preferences</b>',
            btn: 'Update',
          },
        },

        {
          disabled: true,
          ico: 'generalhealth',
          img: '/mc/v3/survey/3.png',
          info: 'Find out what is your overall health condition and get recommendation plan',
          to: '/link-do-ankiety',
          magentoId: '-',

          mobile: {
            title: 'Your <b>General Health</b>',
            btn: 'Check now',
          },

          desktop: {
            title: '<b>Receive a gift</b>',
            text: 'Just share information about <b>your General Health</b>',
            btn: 'Get suprise',
          },

          done: {
            title: 'All done :)',
            text: 'Thank You. You shared information about <b>your General Health</b>',
            btn: 'Update',
          },
        }],
      },
    }
  },

  computed: {
    questionnaireStatuses () {
      return this.$store.state.user.questionnaireStatuses
    },
  },

  methods: {
    isCompleted (code) {
      return this.questionnaireStatuses.find(q => q?.code === code)?.isCompleted || false
    },
  },
}
