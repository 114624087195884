/*
Ladowanie elementow jako bg-image dopiero po wejsciu elementu w viewport
*/
export default {
  data () {
    return {
      isLoaded: false,
    }
  },

  methods: {
    onInViewport () {
      this.isLoaded = true
    },

    getBgImg (imgUrl, options = {}) {
      return this.isLoaded
        ? `--bg-image: url(${this.$img(imgUrl, { format: 'webp', ...options })});`
        : ''
    },
  },
}
